<template>
  <div id="page" class="header-main">
    <iframe v-if="iframe" :src="iframe" frameborder="0"></iframe>
    <header class="filters" :style="{ opacity: iframe ? 0 : 1}">
      Show Streets with 
      <select v-model.number="filters.nb">
        <option value="-1">ALL</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
      </select>
      or more spellings. Showing {{visibleRows.length}} rows.
      <label>
        <input type="checkbox" v-model="filters.tags" value="none"> NO TAG
      </label>
      <label v-for="t in tags" :key="t">
        <input type="checkbox" v-model="filters.tags" :value="t">{{t}}
      </label>
    </header>
    <main>
      <DataTable :items="visibleRows" :columns="columns" :style="{ opacity: iframe ? 0.0 : 1}">
        <template v-slot:regex="{item}">
          <ActionIcon icon="registered" @click="getRegExp(item)" />
          <ActionIcon icon="trash"  @click="deleteRow(item)" />
        </template>
        <template v-slot:tags="{item}">
          <div v-if="item.tags && item.tags.length">
            <span class="tag" v-for="(tag,t) in item.tags" :key="t">
              {{tag}}
              <ActionIcon icon="trash" @click="deleteInfo(item, 'tags', t)" />
            </span>
          </div>
          <div v-else>
            <button v-for="t in tags" :key="t" @click="addTag(item, t)">{{t}}</button>
          </div>
        </template>
        <template v-slot:name_ar="{item}">
          <div v-if="wiki(item, 'ar')" style="float: left">
            <a :href="wiki(item, 'ar')" target="_blank" @mouseenter="iframe = wiki(item, 'ar')" @mouseleave="iframe=null">wiki page</a>
            <ActionIcon icon="trash" @click="deleteInfo(item, 'urls', 'wikipedia:ar')" />
          </div>
          <div v-for="(name, i) in item.name_ar" :key="i" class="name">
            <ActionIcon icon="trash" @click="deleteInfo(item, 'name_ar', i)" />
            <ActionIcon icon="edit" @click="editInfo(item, 'name_ar', i)" />
            {{name}}
            <span v-if="!wiki(item, 'ar')" class="w">
              <a :href="'https://ar.wikipedia.org/wiki/' + name" target="_blank" @mouseenter="iframe = 'https://ar.wikipedia.org/wiki/' + name" @mouseleave="iframe=null" style="color:#888">wiki</a>
              <ActionIcon icon="plus-square" @click="addUrl(item, 'https://ar.wikipedia.org/wiki/' + name)" />
            </span>
          </div>
          <ActionIcon icon="plus-square" @click="addNameAr(item)" />
        </template>
        <template v-slot:name_fr="{item}">
          <div v-if="wiki(item, 'fr')" style="float: right">
            <a :href="wiki(item, 'fr')" target="_blank" @mouseenter="iframe = wiki(item, 'fr')" @mouseleave="iframe=null">wiki page</a>
            <ActionIcon icon="trash" @click="deleteInfo(item, 'urls', 'wikipedia:fr')" />
          </div>
          <div v-for="(name, i) in item.name_fr" :key="i" class="name">
            <ActionIcon icon="trash" @click="deleteInfo(item, 'name_fr', i)" />
            <ActionIcon icon="edit" @click="editInfo(item, 'name_fr', i)" />
            {{name}}
            <span v-if="!wiki(item, 'fr')" class="w">
              <a :href="'https://fr.wikipedia.org/wiki/' + name" target="_blank"  @mouseenter="iframe = 'https://fr.wikipedia.org/wiki/' + name" @mouseleave="iframe=null" style="color:#888">wiki</a>
              <ActionIcon icon="plus-square" @click="addUrl(item, 'https://fr.wikipedia.org/wiki/' + name)" />
            </span>
          </div>
          <ActionIcon icon="plus-square" @click="addNameFr(item)" />
        </template>
        <template v-slot:wikidata_id="{item}">
          <template v-if="item.wikidata_id">
            <a :href="`https://www.wikidata.org/wiki/${item.wikidata_id}`" @mouseenter="iframe = 'https://www.wikidata.org/wiki/' + item.wikidata_id" @mouseleave="iframe=null" target="_blank">{{item.wikidata_id}}</a>
            <ActionIcon icon="trash" @click="deleteInfo(item, 'wikidata_id')" />
          </template>
        </template>
      </DataTable>
    </main>
  </div>
</template>

<script>

import Str from '@/common/helpers/Str.js';
import DataTable from "@/components/DataTable";

export default {
  components: { DataTable },
  data() {
    return {
      iframe: null,
      filters: {
        nb: -1,
        tags: [],
      },
      columns: [
        { name: 'id', type: 'number', width: '50px', }, 
        { name: 'regex', width: '100px', },
        { name: 'name_ar', width: '350px', style: { overflow: 'auto', direction: 'rtl' }, filter: true, },
        { name: 'name_fr', width: '350px', style: { overflow: 'auto', direction: 'ltr' }, filter: true, },
        { name: 'wikidata_id', width: '80px', label: 'wikidata', style: { fontSize: '0.9em' } },
        { name: 'tags', width: '400px', },
      ],
      rows: [],
      tags: [
        'person', 'people', 'quality',
        'place', 'planet', 'country', 'city', 
        'event', 'job', 'man_made',
        'plant', 'animal', 'mineral', 'science',  
        'sport',
      ],
    }
  },
  mounted() {
    this.$api.get('/names').then(j => this.rows = j.data);
  },
  methods: {
    getRegExp(row) {
      Str.copyToClipboard([...row.name_fr, ...row.name_ar].join('|'));
    },
    deleteRow(row) {
      if (confirm('Delete ?')) {
        this.$api.delete(`/names/${row.id}`).then(() => {
          const idx = this.rows.findIndex(r => r.id === row.id);
          this.$delete(this.rows, idx);
        })
      }
    },
    updateRow(row) {
      this.$api.patch(`/names/${row.id}`, row).then(j => {
        const idx = this.rows.findIndex(r => r.id === j.data.id);
        this.$set(this.rows, idx, j.data);
      });
    },
    addNameAr(row) {
      const name = prompt('Enter Name');
      if (name) {
        row.name_ar = [...(row.name_ar || []), name];
        this.updateRow(row);
      }
    },
    addNameFr(row) {
      const name = prompt('Enter Name');
      if (name) {
        row.name_fr = [...(row.name_fr || []), name];
        this.updateRow(row);
      }
    },
    editInfo(row, field, i) {
      const v = prompt('Entr new value', row[field][i]);
      if (v) {
        row[field][i] = v;
        this.updateRow(row);
      }
    },
    deleteInfo(row, field, i) {
      if (confirm('Delete ?')) {
        if ('number' === typeof i) row[field].splice(i, 1);
        else if ('string' === typeof i) delete row[field][i];
        else row[field] = null;
        this.updateRow(row);
      }
    },
    addTag(row, tag) {
      row.tags = [ ...(row.tags || []), tag ];
      this.updateRow(row);
    },
    addUrl(row, url) {
      if (url) url = url.replace(/ /g, '_');
      url = prompt('Enter URL', url);
      let key = 'misc';
      if (url.match('fr.wikipedia')) key = 'wikipedia:fr';
      else if (url.match('ar.wikipedia')) key = 'wikipedia:ar';
      row.urls = {...(row.urls || {}), [key]: url };
      this.updateRow(row);
    },
    wiki(row, lang) {
      return (row.urls && row.urls[`wikipedia:${lang}`])
        ? row.urls[`wikipedia:${lang}`]
        : false; 
    }
  },
  computed: {
    visibleRows() {
      let list = this.rows;
      const { nb, tags } = this.filters;

      if (tags.length === 1 && tags[0] === 'none')
        list = list.filter(r => !r.tags || !r.tags.length);
      else if (tags.length)
        list = list.filter(r => r.tags && r.tags.some(t => tags.includes(t)));

      if (nb === 0) list = list.filter(r => !r.name_fr || !r.name_ar || !r.name_fr.length || !r.name_ar.length);
      else if (nb >= 1) list = list.filter(r => (r.name_fr && r.name_fr.length >= nb && r.name_ar && r.name_ar.length >= nb));

      return list;
    }
  },
}
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
  flex: 1;
}
.tag {
  border: 1px solid #aaa;
  background-color: yellow;
  border-radius: 2px;
  margin-right: 1px;
}

tr:hover td {
  background: #eee;
}

button {
  margin-right: 4px;
}
.name {
  display: flex;
  & > span.w {
    margin-inline-start: auto;

    button {
      margin: 0 5px;
    }
  }
}
iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  filter: brightness(0.9);
}
</style>