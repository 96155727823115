import months from "./months";

export function monthsRegExp() {
    // get all months into regexp
    let terms = [];
    months.forEach(m => {
        terms.push(m.local);
        terms.push(m.french);
        terms.push(m.english);
        terms.push(m.assyrian);
        terms.push(m.local);
        m.other.forEach(o => terms.push(o));
    })

    return terms.join('|');
}

export function isDate(t) {
    // get all months into regexp
    const terms = monthsRegExp();

    const regexp = new RegExp(`(\\d+)? *(${terms}) *(\\d\\d\\d\\d)`);

    return regexp.test(t);
}

