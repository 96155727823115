<template>
  <div id="map" @keypress="mapKeyPress">
    <div id="info">
      [bbox:{{bbox}}]&nbsp;&nbsp;
      Zoom:{{zoom}}&nbsp;&nbsp;
      <span title="Press 'C' while hovering map to copy to clipboard">Lat:{{lat}}&nbsp;Lng:{{lng}}</span>
    </div>
    <MglMap 
      :accessToken="accessToken" 
      :mapStyle="mapStyle" 
      :attributionControl="false"
      :attribution="false"
      :hash="true"
      :tileBoundaries="true"
      @load="onMapLoad"
    >
      <MglFullscreenControl position="top-right" />
      <MglNavigationControl position="top-right" />
      <MglGeolocateControl position="top-right" :positionOptions="{enableHighAccuracy:true}" :trackUserLocation="true" />
      <MglAttributionControl position="bottom-right" />
      <MglScaleControl position="bottom-left" />
    </MglMap>
  </div>
</template>

<script>
import {
  MglMap,
  MglAttributionControl,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl
} from "vue-mapbox";
import { AllStyles } from "@/mapbox/styles";
import { addMobilePitchGesture } from "@/assets/p";
import MyControls from "../mapbox/controls";
import { Misc } from '../tools.js';
import Str from '@/common/helpers/Str.js';
import * as MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';

import ICONS from "@/assets/icons";

const directions = new MapboxDirections({
  accessToken: 'pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2tjOTFvOGg2MTBibjJ6cDhmNWhkYmFhaiJ9.qonyr0JcWzkfcQqWFtmCHQ',
  unit: 'metric',
  profile: 'mapbox/cycling'
});

export default {
  components: {
    MglMap,
    MglAttributionControl,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl
  },
  data() {
    return {
      accessToken: 'pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2tjOTFvOGg2MTBibjJ6cDhmNWhkYmFhaiJ9.qonyr0JcWzkfcQqWFtmCHQ',
      mapStyle: AllStyles['WhereStreets'].style,
      q: '',
      streetsQ: '',
      amenities: [],
      missingSvgs: [],
      searching: false,
      zoom: 0,
      bbox: '',
      lat: 0,
      lng: 0,
      error: '',
      hideResult: false,
    };
  },
  methods: {
    onMapLoad(event) {
      const scope = this;
      const map = event.map;
      this.map = event.map;
      map.vue = scope;

      document.querySelector('canvas.mapboxgl-canvas').focus();

      // for debugging in browser
      window.map = event.map;

      // faster scroll with mouse wheel
      map.on('wheel', function(e) {
        if (e.originalEvent.shiftKey) map.scrollZoom.setWheelZoomRate(1/400);
        else map.scrollZoom.setWheelZoomRate(1/100);
        return true;
      });
      map.scrollZoom.setWheelZoomRate(1/100);
      addMobilePitchGesture(map);

      // add myControls
      map.addControl(new MyControls.SearchControl(), 'top-left');
      map.addControl(new MyControls.StyleSelectorControl({ styles: Object.values(AllStyles), }), 'top-left');
      map.addControl(new MyControls.LanguageSelectorControl());
      if (!Misc.isMobile()) {
        map.addControl(new MyControls.ExternalEditorsControl());
        map.addControl(new MyControls.ShowTilesCoordinatesControl());
      }
      map.addControl(new MyControls.LayersControl(), 'top-left');
      map.addControl(new MyControls.QueryFeatureControl());
      map.addControl(new MyControls.ControlTogglerControl({ control: directions, label: 'A>B' }));

      /**
        * Display Mouse Coordinates
        * Retrigger Search
        */
      map.on('mousemove', function(e) {
        let precision = Math.round(map.getZoom() / 3);
        scope.lat = e.lngLat.lat.toFixed(precision);
        scope.lng = e.lngLat.lng.toFixed(precision);
      });

      const missingImages = [];
      map.on('styleimagemissing', (e) => {

        // Already Being Created ?
        const id = e.id;
        if (missingImages.includes(id)) return;
        missingImages.push(id);

        
        const saveImg = (vb, size, paths, icon) => {

          let fg;
          if (['amenity-parking', 'amenity-fuel'].includes(icon)) {
            fg = '<g fill="#06f">' + paths + '</g>';
          } else if (['shop-car', 'shop-car_repair', 'amenity-car_rental'].includes(icon)) {
            fg = '<g fill="#17e">' + paths + '</g>';
          } else if (['amenity-cafe', 'amenity-restaurant', 
                     'amenity-bar', 'amenity-fast_food', 'amenity-ice_cream'].includes(icon)) {
            fg = '<g fill="#cc6529">' + paths + '</g>';
          } else if (['amenity-clinic', 'amenity-hospital', 'amenity-pharmacy', 'amenity-doctors'].includes(icon)) {
            fg = '<g fill="#f33">' + paths + '</g>';
          } else if (['amenity-bank'].includes(icon)) {
            fg = '<g fill="#183">' + paths + '</g>';
          } else {
            fg = '<g fill="#444">' + paths + '</g>';
          }

          let bg = '<g stroke="#fff" stroke-width="1">' + paths + '</g>';

          const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}px" height="${size}px" viewBox="${vb[0]} ${vb[1]} ${vb[2]} ${vb[3]}">${bg}${fg}</svg>`;
          const imgSrc = 'data:image/svg+xml;base64,' + btoa(svg);
          const img = document.createElement('img');
          
          // document.querySelector('aside').appendChild(img);
          // img.title = id;

          img.addEventListener('load', () => map.addImage(id, img));
          img.src = imgSrc;
        }

        const name = id.substr(0, id.length - 3);
        const size = id.substr(-2) === 'sm' ? 11 : 15;

        const icon = ICONS[name + '-' + size] || ICONS[name];

        if (icon) {
          this.$store.dispatch('getIcon', { path: icon, vue: this}).then ( data => {
            saveImg(data.viewBox, size, data.paths, name);
          })
        } else {
          //this.missingSvgs.push(id);
          console.warn('Missing Icon: ' + id);
          let path = '<path d="m289.999 201.668c-48.705 0-88.329 39.625-88.329 88.33s39.624 88.329 88.329 88.329 88.329-39.624 88.329-88.329-39.624-88.33-88.329-88.33z" fill="param(fill) #000" fill-opacity="255" stroke="param(outline) #FFF" stroke-opacity="255" stroke-width="param(outline-width) 0"/>';
          saveImg([0, 0, 580, 580], 12, path, name);
        }

      });
      map.on('style.load', () => missingImages.splice(0, missingImages.length));


      /**
      * Display Bbox
      */
      function updateBbox() {
        const precision = Math.round(map.getZoom() / 3);
        let bbox = map.getBounds().toArray();
        bbox = [
          bbox[0][1].toFixed(precision), 
          bbox[0][0].toFixed(precision),
          bbox[1][1].toFixed(precision),
          bbox[1][0].toFixed(precision)
        ];
        scope.bbox = bbox.join(',');
        scope.zoom = map.getZoom().toFixed(1);
      }
      map.on('move', updateBbox);
      map.on('move', updateBbox);
      updateBbox();

      this.$emit('load', event);
    },
    getBBox() {
      let bbox = window.map.getBounds().toArray();
      let precision = Math.round(window.map.getZoom() / 3);
      return [
        bbox[0][1].toFixed(precision), 
        bbox[0][0].toFixed(precision),
        bbox[1][1].toFixed(precision),
        bbox[1][0].toFixed(precision)
      ].join(',');
    },
    toggleLayerGroup(lg) {
      AllStyles.WhereStreets.layers
          .filter(l => lg.regex.test(l.id))
          .forEach(l => this.map.setLayoutProperty(l.id, 'visibility', lg.show ? "visible" : "none"));

    },
    mapKeyPress(e) {
      if (e.key === 'c' && document.activeElement && (document.activeElement.tagName === 'CANVAS')) Str.copyToClipboard('lng: ' + this.lng + ', lat: ' + this.lat);
      else if (e.key === 'f') {
        const el = document.querySelector('.mapboxgl-ctrl-search input[type=search]');
        if (document.activeElement !== el) {
          el.focus();
          e.preventDefault();
          return false;
        }
      }
    }
  },
  watch: {
    // fix map size when navigating to another component and back
    $route(to, from){
      if (to.name === 'home') {
        this.map.resize();
      } else if ( (from.name === 'home') && (to.name === 'style-editor')) {
       this.$router.push({ name: 'style-editor', hash: document.location.hash })
      }
    }
  }
};
</script>

<style>
@import "../../node_modules/mapbox-gl/dist/mapbox-gl.css";
@import "../../node_modules/@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
</style>
<style scoped lang="scss">
#map {
  position: absolute;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
}

fieldset.search {
  margin-bottom: 1em;
  border: 1px solid #888;
  padding: 0.5em;

  legend {
    padding: 0 0.5em;
  }
  select {
    width: 100%;
  }
  input[type=checkbox] {
    margin-right: 4px;
  }
  ul {
    margin-left: 0.5em;
    li {
      padding: 0.2em 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  #info {
    display: none;
  }
  .search {
    & > label {
      display: none;
    }
    input, select {
      max-width: 40%;
    }
    select {
      margin-right: 20px;
    }
  }
}

</style>
<style lang="scss">



#info { 
  position: absolute; 
  bottom: 0; left: 160px;
  background: hsla(0,0%,100%,.5); color: rgba(0,0,0,.75);
  padding: 2px; 
  font-family: monospace; 
  font-size: 12px;
  z-index: 1;
}
#info:hover {
  background: hsl(0,0%,100%); color: rgb(0,0,0);
}

.map-overlay {
  font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  position: absolute;
  width: 25%;
  bottom: 100px;
  left: 0;
  padding: 10px;

  .map-overlay-inner {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin: 0 0 10px;
  }

  input {
    background-color: transparent;
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 0;
    cursor: ew-resize;
  }
}


</style>
