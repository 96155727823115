import axios from "axios";
import fs from 'fs';
import md5 from 'md5';

const overpass = {
    url: 'https://overpass-api.de/api/interpreter'
};

/**
 * @param {string} q The overpass query
 * @param {boolean} fromCache Get the answer from /tmp (usefull when debugging to avoid 429)
 * 
 * @return Promise
 * 
 * @example
 *  overpass.query(`[bbox:36.4,10.2,36.9069,10.52];way[highway=residential][name];out meta;`)
 *  .then(function (response) {
 *    doc = new xmldoc.XmlDocument(response.data);
 *  })
 *  .catch(function (error) {
 *    scope.response = error;
 *  });
 *
 */
overpass.query = (q, fromCache = false) => {
    const cacheKey = '/tmp/overpass.' + md5(q);

    if (fromCache) {
        if (fs.existsSync(cacheKey)) {
            return new Promise(resolve => {
                resolve({data: fs.readFileSync(cacheKey, { encoding: 'utf-8'})});
            });
        }
    } 

    return axios.post(overpass.url, q).then(res => {
        fs.writeFileSync(cacheKey, res.data);
        return res;
    });
}

overpass.queryCached = q => { return overpass.query(q, true); }


overpass.centerOfWay = wayId => {
    const q = `[out:json];way(${wayId});out center;`;
    return axios.post(overpass.url, q).then(resp => resp.data.elements[0].center);
}



export default overpass;