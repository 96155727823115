// http://www.localeplanet.com/icu/index.html

export default [
    {
        numeric: 1,
        local: "جانفي",
        french: "Janvier",
        english: "January",
        assyrian: "كانون الثاني",
        other: ["يناير"]
    },
    {
        numeric: 2,
        local: "فيفري",
        french: "Février",
        english: "February",
        assyrian: "شباط",
        other: ["فبراير", "أمشير"]
    },
    {
        numeric: 3,
        local: "مارس",
        french: "Mars",
        english: "March",
        assyrian: "آذار",
        other: ["برمهات"]
    },
    {
        numeric: 4,
        local: "أفريل",
        french: "Avril",
        english: "April",
        assyrian: "نيسان",
        other: ["برمودة", "أبريل"]
    },
    {
        numeric: 5,
        local: "ماي",
        french: "Mai",
        english: "May",
        assyrian: "أيار",
        other: ["بشنس", "مايو"]
    },
    {
        numeric: 6,
        local: "جوان",
        french: "Juin",
        english: "June",
        assyrian: "حزيران",
        other: ["بؤونة", "يونية", "يونيو"]
    },
    {
        numeric: 7,
        local: "جويلية",
        french: "Juillet",
        english: "July",
        assyrian: "تموز",
        other: ["أبيب", "يولية", "يوليوز"]
    },
    {
        numeric: 8,
        local: "أوت",
        french: "Août",
        english: "August",
        assyrian: "آب",
        other: ["مسرى", "أغسطس", "غشت"]
    },
    {
        numeric: 9,
        local: "سبتمبر",
        french: "Septembre",
        english: "September",
        assyrian: "أيلول",
        other: ["توت", "شتنبر"]
    },
    {
        numeric: 10,
        local: "أكتوبر",
        french: "Octobre",
        english: "October",
        assyrian: "تشرين الأول",
        other: ["بابة"]
    },
    {
        numeric: 11,
        local: "نوفمبر",
        french: "Novembre",
        english: "November",
        assyrian: "تشرين الثاني",
        other: ["هاتور", "نونبر"]
    },
    {
        numeric: 12,
        local: "ديسمبر",
        french: "Décembre",
        english: "December",
        assyrian: "كانون الأول",
        other: ["كياهك", "دجنبر"]
    },
];
 