import xmldoc from "xmldoc";
import xmlbuilder from "xmlbuilder";

import overpass from './overpass.js';
import api from './api.js';


const osm = {
    overpass,
    api,
};

/**
 * Transforms an osm xml string into a json object
 *
 * @param {string} xml
 * @param {array} features
 * @param nodeFilter
 * @param wayFilter
 */
osm.osm2json = function(xml, elements = ['nodes', 'ways'], nodeFilter = null, wayFilter = null) {
    const doc = new xmldoc.XmlDocument(xml);
    const json = [];

    if (elements.includes('nodes')) {
        doc.childrenNamed('node').forEach(n => {
            const j = { TYPE: 'node', ID: 'n' + n.attr.id, attrs: {}, tags: {} };
            for (let i in n.attr) j.attrs[i] = n.attr[i];
            n.childrenNamed('tag').forEach(t => j.tags[t.attr.k] = t.attr.v);
            if (!nodeFilter || nodeFilter(j)) {
                json.push(j);
            }
        });
    }

    if (elements.includes('ways')) {
        doc.childrenNamed('way').forEach(w => {
            const j = { TYPE: 'way', ID: 'w' + w.attr.id, attrs: {}, nodes: [], tags: {} };
            for (let i in w.attr) j.attrs[i] = w.attr[i];
            w.childrenNamed('nd').forEach(t => j.nodes.push(t.attr.ref));
            w.childrenNamed('tag').forEach(t => j.tags[t.attr.k] = t.attr.v);
            if (!wayFilter || wayFilter(j)) {
                json.push(j);
            }
        });
    }

    return json;
}


/**
 * Recrates an xml document|string from a json created with osm2json
 * 
 * @param {object} json
 * @param {boolean} asString
 * 
 */
osm.json2osm = function(json, asString = true) {
    const osm = xmlbuilder
      .create('osm', {encoding: 'UTF-8'})
      .att({version: '0.6', generator: 'Node Script'});

    json.filter(e => e.TYPE === 'node').forEach(n => {
        let node = osm.ele('node', n.attrs);
        for (let i in n.tags) if (n.tags[i].trim()) node.ele('tag', { k: i, v: n.tags[i]});
    });

    json.filter(e => e.TYPE === 'way').forEach(w => {
        let way = osm.ele('way', w.attrs);
        w.nodes.forEach(id => way.ele('nd', { ref: id}) );
        for (let i in w.tags) if (w.tags[i].trim()) way.ele('tag', { k: i, v: w.tags[i].trim()});
    })

    return asString ? osm.end({ pretty: true}) : osm;
}


export default osm;