<template>
  <div id="page" class="header-main">
    <header>
      <label>
        <input type="number" v-model="filters.minValues">
        &lt;= Values &lt;=
        <input type="number" v-model="filters.maxValues">
      </label>
      <label>
        <input type="number" v-model="filters.minElements">
        &lt;= Elements &lt;=
        <input type="number" v-model="filters.maxElements">
      </label>
      <label>
        {{rows.length}} tags displayed of {{tags.length}} total.
      </label>
      <div></div>
      <label v-for="(nb, c) in categories" :key="c"
        ><input type="checkbox" v-model="filters.categories[c]"> {{c}} ({{nb}})
      </label>
      <label> <input type="checkbox" v-model="filters.noCategories"> No categories </label>
    </header>
    <main class="table-container">
      <table>
        <thead>
          <tr>
            <th>categories</th>
            <th style="width: 50px; ">xtract</th>
            <th style="width: 200px; ">tag</th>
            <th style="width: 50px; text-align: center">values</th>
            <th style="width: 50px; text-align: center">elements</th>
            <th>Values</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tag in rows" :key="tag.tag">
            <td>
              {{tag.categories}}
              <select @change="addCategory($event, tag)">
                <option></option>
                <option v-for="cat in categoriesList" :key="cat" :value="cat">{{cat}} ({{categories[cat]}})</option>
              </select>
            </td>
            <td style="text-align: center">
              <input type="checkbox" v-model="tag.extracted" @change="toggleExtracted(tag)">
            </td>
            <td>
              <a :href="'https://taginfo.openstreetmap.org/keys/' + tag.tag" target="_blank">{{tag.tag}}</a>
            </td>
            <td style="text-align: center">{{tag.nbValues}}</td>
            <td style="text-align: center">{{tag.nbElements}}</td>
            <td style="font-size: 0.9em">
              <ul>
                <li v-for="v in tag.values" :key="v">
                  <a :href="`https://taginfo.openstreetmap.org/tags/${tag.tag}=${v}`" target="_blank">{{v}}</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </main>
  </div>
</template>

<script>
export default {
  name: "TagsExtracted",
  data() {
    return {
      tags: [],
      categories: {},
      filters: {
        showInvalid: false,
        showNames: false,
        showWikis: false,
        showContacts: false,
        showAddresses: false,
        minElements: 1,
        maxElements: 100000,
        minValues: 0,
        maxValues: 10000,
        noCategories: true,
        categories: {},
      }
    }
  },
  created() {
    this.$api.get('/osm/tags/extracted').then(j => {
      this.tags = j.data;
      const cats = {};
      this.tags.filter(t => t.categories).forEach(t => {
        t.categories.forEach(cat => {
          if (!cats[cat]) cats[cat] = 0;
          cats[cat]++;
        });
      });
      this.categories = { ...cats, _other: null };
    })
  },
  methods: {
    addCategory(event, tag) {
      let cat = event.target.selectedOptions[0].value;
      if (cat) {
        if (cat === '_other') {
          cat = prompt('Enter category name');
        }
        console.log({cat})
        this.$api.patch(`/osm/tags/extracted/${tag.id}`, {
          categories: [...(tag.categories || []), cat]
        }).then(() => {
          // add cat to tag
          if (!tag.categories) tag.categories = [];
          tag.categories.push(tag);
          this.tags = [...this.tags];
          // update list of tags
          if (!this.categories[cat]) this.$set(this.categories, cat, 0);
          this.$set(this.categories, cat, this.categories[cat]+1);
        })
      }
    },
    toggleExtracted(tag) {
        this.$api.patch(`/osm/tags/extracted/${tag.id}`, {
          extracted: tag.extracted
        });
    },
  },
  computed: {
    categoriesList() {
      return Object.keys(this.categories).sort();
    },
    rows() {
      let rows = [...this.tags];

      const { minElements, maxElements, minValues, maxValues } = this.filters;

      rows = rows.filter(t => t.nbValues <= maxValues && t.nbValues >= minValues);
      rows = rows.filter(t => t.nbElements >= minElements && t.nbElements <= maxElements);

      if (this.filters.noCategories)
        rows = rows.filter(t => !t.categories || !t.categories.length)

      Object.entries(this.filters.categories).forEach(([k,v]) => {
        if (v) rows = rows.filter(t => t.categories && t.categories.includes(k));
      })

      return rows;
    }
  }
}
</script>

<style lang="scss" scoped>

main {
  overflow: auto;

  thead > tr > th {
    position: sticky;
    top: 0;
  }
}

ul {
  max-height: 60px;
  overflow: auto;

  li {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding: 0 2px;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-right: 8px;
    white-space: nowrap;
    margin-top: 2px;
  }
}
fieldset {
  padding: 1em;
  
  label {
    font-size: 0.8em;
    border: 1px solid #aaa;
    padding: 3px 6px;
    margin: 2px 5px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    
    input[type="number"] {
      width: 70px;
      border: 1px solid #aaa;
      background: #eee;
      text-align: right;
    }
  }
}
</style>