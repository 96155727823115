<template>
  <div id="page" class="header-main" @keydown.esc="mapIsShown = false" @keydown="onKeyDown">
    <div class="mapPreview" :style="{ opacity: mapIsShown ? 1 : 0.2, zIndex: mapIsShown ? 1000 : -1 }">
      <Map @load="onmapload"/>
    </div>
    <!-- login -->
    <template v-if="!osmCredentials.loggedin" style="color:red">
      <form class="signin" @submit.prevent="checkOsmCredentials">
        OpenStreetMap credentials required
        <input type="text" v-model="osmCredentials.login" placeholder="login">
        <input type="password" v-model="osmCredentials.password" placeholder="password">
        <button type="submit">{{ siginingIn ? 'Signing in...' : 'Sign in' }}</button>
      </form>
    </template>

    <template v-else>
      <!-- header -->
      <header class="flex-row">

        <!-- overpass -->
        <div class="panel">
          <div class="panel-header flex-row">
            <span class="title">Overpass Query</span>
            <button @click="load">Execute</button>&nbsp; (found {{ receivedElements ? receivedElements.length : '' }}) 
            {{loadStatus}}
            <label>
              Limit&nbsp;
              <input type="number" v-model.number="queryLimit" min="1" style="width:70px; text-align: right">
            </label>
            <span class="filler"></span>
            <select @change="selectQuery()" v-model="queries.selected" style="width: 130px;">
              <option v-for="(q, index) in queries.saved" :value="q" :key="index">{{q.label}}</option>
            </select>
            <button @click="saveQuery()">Save Query</button>
          </div>
          <div class="panel-body no-padding wide-form">
            <textarea v-model="simpleQuery" @keydown.ctrl.enter="load" rows="3"></textarea>
          </div>
        </div>

        <!-- console -->
        <div class="panel">
          <div class="panel-header flex-row">
            <span class="title">Console</span>
            <button @click="execEachFeature">Per Feature</button>
            <button @click="execEachTag">Per Tag</button>
            <button @click="execCol">Column</button>
            <button @click="execCell">Cell</button>
          </div>
          <div class="panel-body no-padding wide-form">
            <textarea v-model="coder.global"></textarea>
          </div>
        </div>

        <pre v-if="searchResult" id="searchResult">{{ searchResult }} <button @click="searchResult = ''">cls</button></pre>
 
        <!-- toolbar -->
        <div>
          <div id="toolbar">
            <div class="fieldSel tags">
              Tags ...
              <div class="list">
                <ul>
                  <li v-for="f in sortedFields" :key="'li'+f.name">
                    <label :class="{ none: !f.count }">
                      <input type="checkbox" v-model="f.visible">{{f.name}}<span v-if="f.count">{{f.count}}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="fieldSel">
              Rue, نهج, ... 
              <div class="list" title="click to copy">
                <div v-for="(names, i) in streetCategories" :key="i">
                  <span 
                    v-for="c in names" :key="c" 
                    class="copiable" 
                    @click="copyToClipboard(c)"
                    >{{c}}</span>
                </div> 
              </div>
            </div>
            <div class="fieldSel">
              Months ... 
              <div class="list" title="click to copy">
                <div v-for="(month, i) in months" :key="i">
                  <span 
                    v-for="(m,j) in Object.values(month).flat().slice(1)" :key="j"
                    class="copiable" 
                    @click="copyToClipboard(m)"
                    >{{m}}</span>
                  <span class="copiable" @click="copyToClipboard(monthRegexp(month))">A|L|L</span>
                </div>
              </div>
            </div>
            <div class="fieldSel">
              Queries ... 
              <div class="list" title="click to copy">
                <div v-for="(queries, i) in sampleQueries" :key="i">
                  <span 
                    v-for="(q,label) in queries" :key="label"
                    class="copiable" 
                    @click="copyToClipboard(q)"
                    >{{label}}</span>
                </div>
              </div>
            </div>
          </div>
          <button v-if="saveStatus" class="button save">{{saveStatus}}</button>
          <button v-else-if="modifiedElements.length" @click="save" class="button save">save {{modifiedElements.length}} changes</button>
        </div>

 
      </header>

      <main style="flex-grow: 1">
        <DataTable 
          v-if="receivedElements && receivedElements.length" 
          :items="receivedElements" 
          :columns="tableColumns" 
          defaultColumnWidth="200px" 
          :filter="true" 
          filtersubkey="tags"
          >
          <!-- actions -->
          <template v-slot:osmid="{item:feature}">
            <div class="actions" :class="{ modified: modifiedElements.includes(feature.ID), copied: feature === copiedFeature }">
              <a :href="osmLink(feature)" target="_blank" :title="title(feature)" tabindex="-1">{{feature.TYPE}}</a>
              <span class="type" v-if="kind(feature)">{{ kind(feature) }}</span>  
              <button @click="showMap(feature)" title="Show on map" tabindex="-1">m</button>  
              <button @click="gtranslate(feature)" title="Google Translate" tabindex="-1">tr</button>  
              <button @click="search(feature)" title="Search Similar" tabindex="-1">s</button>  
              <button 
                @click="revert(feature)" 
                @mouseover="$set(showReverted, feature.ID, true)"
                @mouseout="$set(showReverted, feature.ID, false)"
                :disabled="!modifiedElements.includes(feature.ID)" 
                title="Revert changes"
                tabindex="-1"
                >r</button>  
              <button title="copy" class="copy" @click="copiedFeature = (feature === copiedFeature) ? null : feature" tabindex="-1">c</button>  
              <button title="paste" @click="paste(feature)" :disabled="!copiedFeature" tabindex="-1">p</button>  
            </div>
          </template>
          <!-- inputs -->
          <template v-slot:[f.name]="{item:feature}" v-for="f in visibleTags">
            <input 
              v-if="!showReverted[feature.ID]" 
              type="text" 
              class="it" 
              @focus="focused = [feature, f.name]" 
              v-model="feature.tags[f.name]" 
              :key="f.name"
              >
            <input v-else type="text" class="it" disabled v-model="feature._oldTags[f.name]" :key="f.name">
          </template>
        </DataTable>
      </main>
    </template>
  </div>
</template>

<script>

import OSM from '../../common/osm';
import months from '@/lexical/months';
import { isDate } from '@/lexical/translator.dates';
import { simplifyName, categories as streetCategories } from '@/lexical/streetNames';
import 'highlight.js/styles/github.css';
import Map from '@/components/Map';

import DataTable from '@/components/DataTable';

export default {
  components: {
    Map,
    DataTable,
  },
  data() {
    return {
      tab: 'table',
      leftView: 'overpass',
      focused: null,
      siginingIn: false,
      coder: {
        active: 'global',
        global: "if ($node) {\n  $tags['name:fr'] = $tags['name'];\n}",
      },
      queries: {
        saved: [],
        selected: null,
      },
      query: ``,
      simpleQuery: `nwr(area.boundaryarea)[amenity=bank];`,
      queryLimit: 500,
      sampleQueries: [
        {
          "Highways": `way[highway][~"name"~"",i];`,
          "no fr": `way[highway][name][!"name:fr"];`,
          "persian numbers": `nwr[~"name"~"[٠١٢٣٤٥٦٧٨٩]"];`,
        }
      ],
      receivedElements: null, // json being used
      mapIsShown: false,
      streetCategories,
      months,
      modifiedElements: [],
      status: "Waiting for you to do something ...",
      s: {},
      showReverted: {},
      saveStatus: null,
      loadStatus: null,
      searchResult: '',
      copiedFeature: null,
      osmCredentials: { login: null, password: null, loggedin: false },
      tags: [],
      defaultTags: [
          { name: "name", dir: "rtl", visible: true },
          { name: "name:fr", visible: true },
          { name: "name:ar", dir: "rtl", visible: true },
          { name: "name:en", visible: true },
          { name: "long_name", dir: "rtl", visible: false },
          { name: "long_name:fr", visible: false },
          { name: "long_name:ar", dir: "rtl", visible: false },
          { name: "long_name:en", visible: false },
          { name: "short_name", dir: "rtl", visible: false },
          { name: "short_name:fr", visible: false },
          { name: "short_name:ar", dir: "rtl", visible: false },
          { name: "short_name:en", visible: false },
          { name: "old_name", dir: "rtl", visible: false },
          { name: "old_name:fr", visible: false },
          { name: "old_name:ar", dir: "rtl", visible: false },
          { name: "old_name:en", visible: false },
          { name: "alt_name", dir: "rtl", visible: false },
          { name: "alt_name:fr", visible: false },
          { name: "alt_name:ar", dir: "rtl", visible: false },
          { name: "alt_name:en", visible: false },
          { name: "ref", dir: "rtl", visible: false },
          { name: "ref:fr", visible: false },
          { name: "ref:ar", dir: "rtl", visible: false },
          { name: "addr:street", dir: "rtl", visible: false },
          { name: "addr:street:fr", visible: false },
          { name: "addr:street:ar", dir: "rtl", visible: false },
      ]
    };
  },
  methods: {
    onmapload(event) {
      this.map = event.map;
    },
    execEachFeature() {
      const code = `
        this.receivedElements.forEach($feature => {
            const $tags = $feature.tags;
            const $node = $feature.TYPE === 'node';
            const $way  = $feature.TYPE === 'way';
            const $rel  = $feature.TYPE === 'rel';
            ${this.coder.global}
        })`;
      eval(code);
      this.receivedElements = [...this.receivedElements];
    },
    execEachTag() {
      const code = `
        this.receivedElements.forEach($feature => {
          this.visibleTags.forEach(t => {
            const $tags = $feature.tags;
            const $node = $feature.TYPE === 'node';
            const $way  = $feature.TYPE === 'way';
            const $rel  = $feature.TYPE === 'rel';
            const $t = t.name;
            let $tag = $tags[$t];
            if (undefined === $tag) $tag = '';
            const result = (() => { ${this.coder.global} })();
            if (undefined !== result) $tags[$t] = result;
          })
        })`;
      eval(code);
      this.receivedElements = [...this.receivedElements]
    },
    execCol() {
      if (!this.focused) alert('You need to set focus first');
      const code = `
        this.receivedElements.forEach($feature => {
          const $tags = $feature.tags;
          const $node = $feature.TYPE === 'node';
          const $way  = $feature.TYPE === 'way';
          const $rel  = $feature.TYPE === 'rel';
          let $tag = $tags[this.focused[1]];
          if (undefined === $tag) $tag = '';
          const result = (() => { ${this.coder.global} })();
          if (undefined !== result) $tags[this.focused[1]] = result;
        })`;
      eval(code);
      this.receivedElements = [...this.receivedElements]
    },
    execCell() {
      if (!this.focused) alert('You need to set focus first');
      const code = `
        const $feature = this.focused[0];
        const $tags = $feature.tags;
        let $tag = $feature.tags[this.focused[1]];
        if (undefined === $tag) $tag = '';
        $tags[this.focused[1]] = ${this.coder.global}
      `;
      eval(code);
      this.receivedElements = [...this.receivedElements]
    },
    load() {
      this.modifiedElements = [];
      this.receivedElements = [];
      this.tags = this.defaultTags.map(t => ({...t, count: 0 }));
      this.loadStatus = "quering overpass ...";

      // this.$api.get('/fakes/overpass-response-meta.xml')
      this.$api.post(OSM.overpass.url, this.query)
      .then(response => {
        this.loadStatus = "parsing result ...";

        // xml => json
        this.receivedElements = OSM.osm2json(response.data);

        // some processing
        const tags = {};
        this.receivedElements.forEach(element => {
          // remove empty tags
          for (let t in element.tags) if (element.tags[t] === "") delete element.tags[t];

          // save original tags for reverting
          element._oldTags = { ...element.tags };

          // auto display filled columns
          for (let tag in element.tags) {
            if (!tags[tag]) tags[tag] = 0;
            tags[tag]++;
          }
        });

        for (let tag in tags) {
          const t = this.tags.find(f => f.name === tag);
          if (t) {
            t.count = tags[tag];
          } else {
            this.tags.push({ name: tag, visible: false, count: tags[tag] });
          }
        }

        // sort
        this.receivedElements = this.receivedElements.sort((a,b) => {
          if (a.tags.name > b.tags.name) return 1;
          if (a.tags.name < b.tags.name) return -1;
          return a.tags["name:fr"] > b.tags["name:fr"] ? 1:-1
        });

        this.loadStatus = null;
        // localStorage.setItem('lastQuery', this.query);
        localStorage.setItem('lastSimpleQuery', this.simpleQuery);
      })
      .catch(err => {
        let msg = "";
        if (err.toString().match(/\b400\b/)) msg = "Please check your Overpass query.";
        if (err.toString().match(/\b504\b/)) msg = "Overpass Server is under huge load. Please try again later..";
        alert(err + "\n\n" + msg);
        console.error(err)
        this.loadStatus = null;
        this.response = err;
      });
    },
    save() {
      let commitMessage = prompt('Commit message', 'Fixing street names in Tunisia');
      if (commitMessage) {

        // build json change
        const json = [];
        this.modifiedElements.forEach(elementID => {
          const newElement = this.receivedElements.find(w => w.ID === elementID);
          const j = JSON.parse(JSON.stringify(newElement));
          j.attrs = {
            id: j.attrs.id,
            version: j.attrs.version,
            changeset: "_CHANGESET_"
          };

          if ('node' === newElement.TYPE) {
            j.attrs['lat'] = newElement.attrs.lat;
            j.attrs['lon'] = newElement.attrs.lon;
          }

          json.push(j);
        });

        // upload
        const { login, password } = this.osmCredentials;
        this.saveStatus = 'Saving ...';
        this.$api
          .put('/osm/update', { login, password, json, commitMessage })
          .then(resp => {
            this.saveStatus = null;
            this.modifiedElements = [];
            this.receivedElements = [];
            alert(resp.data);
          });
      }
    },
    paste(w) {
      this.visibleTags.forEach(f => {
        w.tags[f.name] = this.copiedFeature.tags[f.name];
      })
      this.$forceUpdate();
    },
    revert(feature) {
      feature.tags = { ...feature._oldTags };
      this.showReverted[feature.ID] = false;
    },
    auto(feature) {
      const arabic = /[\u0600-\u06FF]/;
      // french name in arabic field ?
      if (feature.tags['name:ar'] && !feature.tags['name:fr'] && feature.tags['name:ar'].match(/[a-z]/i)) {
        feature.tags['name:fr'] = feature.tags['name:ar'];

        // name => name:ar ?
        if (feature.tags.name && arabic.test(feature.tags.name)) {
          feature.tags['name:ar'] = feature.tags.name;
        } else {
          delete feature.tags['name:ar'];
        }
      } else if (feature.tags['name:ar'] && !feature.tags['name:fr'] && feature.tags['name'] && feature.tags['name'].match(/[a-z]/i)) {
        // default is french but arabic exists
        feature.tags['name:fr'] = feature.tags['name'];
        feature.tags['name'] = feature.tags['name:ar'];
      } else if (feature.tags['name'] && feature.tags['name'].match(/^(:?rue [0-9]+|نهج [0-9]+)$/i)) {
        // default is french but arabic exists
        let m = feature.tags['name'].match(/[0-9]+/);
        feature.tags['name:fr'] = 'Rue ' + m[0];
        feature.tags['name'] = feature.tags['name:ar'] = 'نهج ' + m[0];
        this.$forceUpdate();
      }

      if ( (feature.tags['name:fr'] === feature.tags['name']) && feature.tags['name:ar'] && arabic.test(feature.tags['name:ar'])) {
         feature.tags['name'] = feature.tags['name:ar'];
      }

      // Persian numbers
      for (let t in feature.tags) {
        if (feature.tags[t] && feature.tags[t].match(/[٠١٢٣٤٥٦٧٨٩]/)) {
          feature.tags[t] = feature.tags[t] .replace(/٠/g, 0) .replace(/١/g, 1) .replace(/٢/g, 2) .replace(/٣/g, 3) .replace(/٤/g, 4) .replace(/٥/g, 5) .replace(/٦/g, 6) .replace(/٧/g, 7) .replace(/٨/g, 8) .replace(/٩/g, 9)
        }
      }

      // capitalize some words
      for (let t in feature.tags) {
        if (feature.tags[t]) {
          feature.tags[t] = feature.tags[t].replace(/\brue\b/i, 'Rue');
          feature.tags[t] = feature.tags[t].replace(/\bavenue\b/i, 'Avenue');
          feature.tags[t] = feature.tags[t].replace(/\bboulevard\b/i, 'Boulevard');
          feature.tags[t] = feature.tags[t].replace(/ +/gi, ' ');
          feature.tags[t] = feature.tags[t].trim();
        }
      }
    },
    osmLink(feature) {
      return `https://www.openstreetmap.org/${feature.TYPE}/${feature.attrs.id}`;
    },
    search(feature) {
      const tag = this.focused[1] || 'name';
      let q = feature.tags[tag];
      q = simplifyName(q);
      q = q.replace(/\bb[ie]n\b/gi, '.*');
      q = q.replace(/abd(.l)?/gi, '.*');
      q = q.replace(/[aeiouéèêïà]/gi, '.?');
      q = q.replace(/ +/g, ' ');
      q = encodeURI(q)

      this.$api
        .get(`/db/search?q=${q}`)
        .then(j => {
          let resp = [];
          j.data.features.forEach(feat => {
            for (let tag in feat.properties) {
              if (feat.properties[tag] && tag.match(/name/) && tag !== 'short_name') {
                resp.push(feat.properties[tag]);
              }
            }
          });

          resp = resp
                .map(v => v.trim())
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort()
                ;

          this.searchResult = resp.join("\n");
        })
        .catch(error => {
          let msg = "";
          if (error.toString().match(/\b400\b/)) msg = "Please check your Overpass query.";
          alert(error + "\n\n" + msg);
          this.response = error;
        });
    },
    autoAll() {
      this.receivedElements.forEach(w => this.auto(w));
    },
    kind(feature) {
      if (isDate(feature.tags.name || "")) return 'date';
      return '';
    },
    copyToClipboard(str) {
      const el = document.createElement('textarea');
      el.style.opacity = '0';
      el.style.position = 'absolute';
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    showMap(feature) {
      if (this.mapIsShown === feature.attrs.id) {
        this.mapIsShown = false;
        return;
      }

      // for now save center in localStorage (overpass is not cheap)
      let center;
      if ('node' === feature.TYPE) {
        this.map.flyTo({ center: { lat: feature.attrs.lat, lon: feature.attrs.lon }, zoom: 18 });
      } else {
        center = localStorage.getItem('osm-centerofway-' + feature.attrs.id);
        if (center) {
          center = JSON.parse(center);
          this.map.flyTo({ center, zoom: 18 });
        } else {
          try {
            OSM.overpass.centerOfWay(feature.attrs.id).then(center => {
              localStorage.setItem('osm-centerofway-' + feature.attrs.id, JSON.stringify(center));
              this.map.flyTo({ center, zoom: 18 });
            });
          } catch (e) {
            console.error(e);
            alert('Overpass error');
            return;
          }
        }
      }

      this.mapIsShown = feature.attrs.id;
    },
    gtranslate(feature) {
      const a = document.createElement('a');
      a.href = `https://translate.google.com/#view=home&op=translate&sl=fr&tl=ar&text=${feature.tags.name}`;
      a.target = 'gtranslate';
      a.click();
    },
    checkOsmCredentials(hideError) {
      this.siginingIn = true;
      const { login, password } = this.osmCredentials;
      this.$api
        .post('/osm/permissions', { login, password })
        .then(resp => {
          this.siginingIn = false;
          if (JSON.stringify(resp.data).match('allow_write_api')) {
            localStorage.setItem('osm.login', login);
            localStorage.setItem('osm.password', password);
            this.osmCredentials.loggedin = true;
          } else {
            if (hideError !== true) alert("Erreur de login/mdp.")
          }
        });
    },
    loadQueries() {
      let queries = localStorage.getItem('savedQueries');
      if (queries) this.queries.saved = JSON.parse(queries);
    },
    selectQuery() {
      if (this.queries.selected) {
        this.query = this.queries.selected.query;
        this.queries.selected = null;
      }
    },
    saveQuery() {
      const label = prompt('Name your query');
      if (label) {
        let queries = localStorage.getItem('savedQueries');
        if (!queries) queries = [];
        else queries = JSON.parse(queries);

        queries.push({query: this.query, label });
        localStorage.setItem('savedQueries', JSON.stringify(queries));
        this.loadQueries();
      }
    },
    monthRegexp(m) {
      return [m.local, m.french, m.english, m.assyrian].join('|');
    },
    computeDiff() {

        this.modifiedElements = [];

        // diff
        this.receivedElements.forEach(element => {
          if (JSON.stringify(element.tags) !== JSON.stringify(element._oldTags)) {
            this.modifiedElements.push(element.ID);
          }
        });
    },
    title(feature) {
      const f = {...feature};
      ['nodes', '_oldTags', 'ID', 'TYPE', '_rid_'].forEach(t => delete f[t]);
      return JSON.stringify(f, null, '   ');
    },
    onKeyDown(e) {
      if (e.ctrlKey && e.key === 'e') {
        e.preventDefault();
        e.stopPropagation();
        if (e.altKey) this.execCol();
        else this.execCell();
      }
    }
  },
  computed: {
    visibleTags: function() {
      return this.tags.filter(f => f.visible);
    },
    sortedFields: function() {
      return this.tags.concat().sort((a,b) => a.name > b.name ? 1 : -1);
    },
    tableColumns() {
      return [
        {
          name: 'osmid',
          width: '124px',
          filter: false,
          style: { paddingLeft: 0, textAlign: 'right' },
        }, 
        ...this.visibleTags.map(f => f.name.match(/(name|:ar)$/) ? {...f, style: {direction: 'rtl'}} : f),
      ];
    },
  },
  created() {
    const lastQ = localStorage.getItem('lastSimpleQuery');
    if (lastQ) this.simpleQuery = lastQ;
  },
  mounted() {
    const login = localStorage.getItem('osm.login');
    const password = localStorage.getItem('osm.password');
    this.osmCredentials = Object.assign({}, this.osmCredentials, { login, password });
    if (this.osmCredentials) this.checkOsmCredentials(true);
    this.loadQueries();
  },
  watch: {
    receivedElements: {
      handler(){
        this.computeDiff();
      },
      deep: true
    },
    simpleQuery: {
      handler(){
        this.query = `area["name:en"="Tunisia"]->.boundaryarea;
  (
  ${this.simpleQuery}
  );
  out meta ${this.queryLimit};`
      },
      immediate: true
    },
    queryLimit: {
      handler(){
        this.query = `area["name:en"="Tunisia"]->.boundaryarea;
  (
  ${this.simpleQuery}
  );
  out meta ${this.queryLimit};`
      },
      immediate: true
    },
  },
}
</script>
<style lang="scss" scoped>

main {
  overflow: hidden;
}

header {
  padding: 0 !important;
}

.panel {
  height: 90px;
  flex: 1;
}

button {
  border: 1px solid #888;
  padding: 1px;
  margin-left: 1px;
}

textarea {
  height: 100%;
  resize: none;
  border: 0;
  padding: 4px;
  font-size: 80%;
}

input.it {
  padding: 0;
  border: 0;
  outline: 0;
  width: 100%;
  text-overflow: ellipsis;
}

div.modified {
  background-color: orange;
}
div.copied {
  background: yellow;
  button.copy {
    text-decoration: line-through;
  }
}

#searchResult {
  position: fixed;
  top: 72px;
  width: 360px;
  background-color: #ffe12a;
  max-height: 120px;
  overflow: auto;
  left: 630px;
  box-shadow: 0 0 4px 0;
  z-index: 22;

  & > button {
    position: absolute;
    right: 3px;
    top: 3px;
  }
  &:hover {
    max-height: 300px;
  }
}
.osmid {
  width: 1px;
  white-space: nowrap;
}
#toolbar {
  width: 300px;
  .fieldSel {
    display: inline-block;
    padding: 0 3px;
    background: #fff;
    border: 1px solid #000;
    position: relative;

    &.tags > .list {
      width: 980px;

      & > ul {
        column-count: 7;
        font-size: 0.8rem;

        label {
          display: inline-flex;
          align-items: center;
          gap: 3px;
          line-height: 1.1rem;
          &.none {
            opacity: 0.7;
          }
          span {
            color: #6d0380;
            transform: scale(0.8);
            background: white;
          }
        }
      }
    }

    & > .list {
      display: none;
      position: absolute;
      z-index: 1;
      right: 0;
      white-space: nowrap;
      border: 1px solid #000;
      padding: 2px 6px;
      background: #fff;
      & > label {
        display: block;
      }
    }

    &:hover > .list {
      display: block;
    }
}
}

.type {
  border: 1px solid gray;
  background-color: yellow;
}
.copiable {
  background: #eee;
  margin: 4px 2px;
  display: inline-block;
  font-size: 80%;
  padding: 2px 6px;
  border: 1px solid #ccc;
  border-radius: 9px;
  cursor: pointer;
  &:hover {
    color: #000;
  }
}

pre {
  font-size: 12px;
}

.mapPreview {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  width: 40vw;
  height: 100vh;
  border: 2px solid black;
}

.flex-row {
    display: flex;
    flex-flow: row;

    & > .filler {
        margin-left: auto;
    }
}

.panel-header.flex-row {
  align-items: center;
}

.no-padding {
    padding: 0 !important;
}

footer {
  display: flex;
  width: 100%;
  textarea {
    flex-grow: 1;
    height: 4.5em;
  }
}

::v-deep .cell {
  padding-bottom: 3px;

  & > .actions {
    text-align: right;

    & > a {
      width: 36px;
      text-align: center;
      display: inline-block;
    }
  }

  & > .actions > button {
    min-width: 12px;
  }
}

form.signin {
  width: 20em;
  background-color: #dfe2b9;
  margin: 3em auto;
  padding: 1em;
  border: 1px solid #aaa;

  input {
    display: block;
    width: 100%;
    padding: 6px;
    margin: 0.5em 0;
  }
}
</style>
