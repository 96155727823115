<template>
  <div class="data-table">
    <header>
      <div class="row" :style="headerRowStyle">
        <div class="label" 
          v-for="(col, key) in cols" :key="key"
          :style="headerCellStyle(col)"
        >{{col.label || col.name}}</div>
      </div>
      <div class="row filters" :style="headerRowStyle">
        <div class="filter" v-for="(col, key) in cols" :key="key" :style="headerCellStyle(col)">
          <input type="text" v-if="(filter && col.filter !== false) || col.filter" v-model="filters[key]">
        </div>
      </div>
    </header>
    <main @mousewheel="onmousewheel" ref="main"> 
      <div class="data" :style="dataStyle">
        <!-- row -->
        <div class="row" :style="rowStyle" v-for="row in displayedRows" :key="row._rid_">
          <!-- cell -->
          <div class="cell" 
            v-for="(col, i) in cols" :key="i"
            :style="cellStyle(col, i)"
            :class="cellClass(row._rid_, i)"
            :data-type="col.type"
            @aclick="selectCell(row._rid_, i)"
            >

            <template v-if="hasSlot(col.name)">
              <slot :name="col.name" :item="row"></slot>
            </template>
            <template v-else>
              {{row[col.value]}}
            </template>
          </div>
        </div>
      </div>
      <svg ref="vs" class="scroll vScroll" :viewport="vScrollViewport" :style="vScrollStyle">
        <rect x="0" :y="layout.vScroll.rectY" :height="layout.vScroll.rectHeight" @mousedown.prevent="onVSmousedown" rx="5" ry="5" width="100%" />
      </svg>
      <svg ref="hs" class="scroll hScroll" :viewport="hScrollViewport" :style="hScrollStyle">
        <rect :x="layout.hScroll.rectX" y="0" height="100%" :width="layout.hScroll.rectWidth" @mousedown.prevent="onHSmousedown" rx="5" ry="5" />
      </svg>
    </main>
  </div>
</template>

<script>
import Str from '@/common/helpers/Str.js';

export default {
  name: "DataTable",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    defaultColumnWidth: {
      type: String,
      default: '150px',
    },
    invertScroll: {
      type: Boolean,
      default: false,
    },
    wheelPrecision: {
      type: Number,
      default: 40,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    autoScroll: {
      type: Boolean,
      default: false,
    },
    filtersubkey: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      rowHeight: 25,
      allRows: this.items.map((r, _rid_) => ({...r, _rid_})),
      selectedCells: [],
      cols: [],
      filters: {},
      layout: {
        wrapper: {
          height: 0,
        },
        data: {},
        colsTotalWidth: 0,
        vScroll: {
          width: 10,
          rectY: 0,
          rectHeight: 0,
          rowPixels: 1,
        },
        hScroll: {
          width: 10,
          height: 10,
          rectX: 0,
          rectWidth: 0,
        },
        firstRow: 0,
        firstCol: 0,
        mouseY: null,
      }
    }
  },
  
  created() {
  },
  mounted() {
    const resizeObserver = new ResizeObserver(entries => {
      const L = this.layout;
      L.wrapper = entries[0].contentRect;
      this.updateVScrollRectHeight();
      this.updateHScrollRectWidth();
    });
    resizeObserver.observe(this.$refs.main);
  },
  methods: {
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    selectCell(rowIdx, colIdx) {
      this.selectedCells = [`${rowIdx}-${colIdx}`];
    },
    onmousewheel(e) {
      e.preventDefault();
      const move = (this.invertScroll ? -1 : 1) * (e.deltaY / this.wheelPrecision);
      this.setFirstRow(this.layout.firstRow + move);
    },
    onVSmousedown(e) {
      this.layout.mouseY = e.screenY - this.layout.vScroll.rectY;
      window.addEventListener('mousemove', this.onVScroll);
      window.addEventListener('mouseup', () => window.removeEventListener('mousemove', this.onVScroll));
    },
    onHSmousedown(e) {
      this.layout.mouseX = e.screenX - this.layout.hScroll.rectX;
      window.addEventListener('mousemove', this.onHScroll);
      window.addEventListener('mouseup', () => window.removeEventListener('mousemove', this.onHScroll));
    },
    onVScroll(e) {
      const L = this.layout;
      this.setFirstRow((e.screenY - L.mouseY) / L.vScroll.rowPixels);
    },
    onHScroll(e) {
      const L = this.layout;
      this.setFirstCol((e.screenX - L.mouseX) / L.hScroll.colPixels);
    },
    setFirstRow(x) {
      if (x < 0) x = 0;
      else if (x >= this.allRows.length) x = this.allRows.length - 1;

      this.layout.firstRow = x;
      this.updateVScrollRectY();
    },
    setFirstCol(x) {
      if (x < 0) x = 0;
      else if (x >= this.columns.length) x = this.columns.length - 1;

      this.layout.firstCol = x;
      this.updateHScrollRectX();
    },
    updateVScrollRectY() {
      if (!this.allRows.length) return 0;
      const L = this.layout;
      const ratio = L.firstRow / (this.allRows.length-1);
      this.layout.vScroll.rectY = ratio * (L.wrapper.height - L.vScroll.rectHeight);
    },
    updateVScrollRectHeight() {
      const L = this.layout;
      if (L.wrapper.height && this.allRows.length) {
        L.vScroll.rowPixels = L.wrapper.height / this.allRows.length;
        L.vScroll.rectHeight = Math.max(30, Math.min(L.wrapper.height, L.vScroll.rowPixels));
      }
    },
    updateHScrollRectX() {
      if (!this.columns.length) return 0;
      const L = this.layout;
      const ratio = L.firstCol / (this.columns.length-1);
      this.layout.hScroll.rectX = ratio * (L.wrapper.width - L.hScroll.rectWidth);
    },
    updateHScrollRectWidth() {
      const L = this.layout;
      if (L.wrapper.width && this.columns.length) {
        L.hScroll.colPixels = L.wrapper.width / this.columns.length;
        L.hScroll.rectWidth = Math.max(30, Math.min(L.wrapper.width, L.hScroll.colPixels));
      }
    },
    cellClass(rowIdx, colIdx) {
      return {
        selected: this.selectedCells.includes(`${rowIdx}-${colIdx}`),
      }
    },
    cellStyle(col) {
      if (col.name === 'osmid' && this.layout.firstCol > 0) {
        return {
          ...col.style,
          background: '#fff',
          transform: 'translateX(' + (parseInt(this.layout.colsTotalWidth * this.layout.firstCol / this.columns.length)) + 'px)',
        }
      } else {
        return col.style;
      }
    },
    headerCellStyle(col) {
      if (col.name === 'osmid' && this.layout.firstCol > 0) {
        return {
          ...col.headerStyle,
          transform: 'translateX(' + (parseInt(this.layout.colsTotalWidth * this.layout.firstCol / this.columns.length)) + 'px)',
        }
      } else {
        return col.headerStyle;
      }
    }
  },
  computed: {
    /*************************************** S C R O L L I N G */
    vScrollViewport() {
      return `0 0 ${this.layout.vScroll.width}px ${this.layout.wrapper.height - 10}px`;
    },
    hScrollViewport() {
      return `0 0 ${this.layout.wrapper.width - 10}px ${this.layout.hScroll.height}px`;
    },
    vScrollStyle() {
      return {
        width: this.layout.vScroll.width + 'px',
        height: this.layout.wrapper.height - 10 + 'px',
      };
    },
    hScrollStyle() {
      return {
        width: this.layout.wrapper.width - 10 + 'px',
        height: this.layout.hScroll.height + 'px',
      };
    },
    /*********************************************** S T Y L E */
    rowStyle() {
      const style = {
        width: this.layout.colsTotalWidth + 'px',
        minHeight: this.rowHeight + 'px',
      }

      if (this.layout.firstCol > 0) {
        style.marginLeft = '-' + (parseInt(this.layout.colsTotalWidth * this.layout.firstCol / this.columns.length)) + 'px';
      }
      return style;
    },
    headerRowStyle() {
      const style = {
        width: this.layout.colsTotalWidth + 'px',
        height: this.rowHeight + 'px',
      }
      if (this.layout.firstCol > 0) {
        style.marginLeft = '-' + (parseInt(this.layout.colsTotalWidth * this.layout.firstCol / this.columns.length)) + 'px';
      }
      return style;
    },
    dataStyle() {
      return {
        width: this.layout.colsTotalWidth + 'px',
        height: (this.allRows.length * this.rowHeight) + 'px',
      }
    },
    displayedRows() {
      const L = this.layout;
      let rows = this.allRows;
      Object.keys(this.filters).forEach(key => {
        if (this.filters[key] !== '') {
          rows = rows.filter(row => {
            if (this.filtersubkey) {
              row = row[this.filtersubkey];
            }
            return row[key] && Str.searchIn(this.filters[key], JSON.stringify(row[key]));
          });
        }
      })
      return rows.slice(L.firstRow, L.firstRow + (L.wrapper.height / 20));
    },
  },
  watch: {
    items: {
      handler(newValue, oldValue) { 
        this.allRows = this.items;
        this.allRows.forEach((r,_rid_) => r._rid_ = _rid_)
        this.layout.data.height = this.items.length * this.rowHeight;
        if (this.autoScroll && (newValue.length !== oldValue.length)) {
          this.setFirstRow(0);
          this.updateVScrollRectHeight();
        }
      },
      immediate: true
    },
    filters: {
      deep: true,
      handler() {
        this.setFirstRow(0);
      },
    },
    columns: {
      handler() { 
        // array => object ?
        let cols = {};
        if (Array.isArray(this.columns)) {
          this.columns.forEach(col => {
            let name, def;
            if ('string' === typeof col) {
              name = col;
              def = { name, value: name };
            } else {
              if (!col.name)
                throw new Error('You must provide the name propery in cols definition');
              name = col.name;
              if (!col.value) col.value = name;
              def = col;
            }
            cols[name] = def;
          })
        }

        // add default properties
        Object.keys(cols).forEach(key => {
          cols[key] = {
            type: 'string',
            width: this.defaultColumnWidth,
            headerStyle: {
            },
            style: {
            },
            ...cols[key],
          };
          if (cols[key].width) {
            cols[key].style.width = cols[key].width;
            cols[key].headerStyle.width = cols[key].width;
          }
        });

        // update total width
        let totalWidth = 0;
        Object.keys(cols).forEach(key => { totalWidth += parseInt(cols[key].style.width); });
        this.layout.colsTotalWidth = totalWidth;
        this.updateHScrollRectWidth();

        this.cols = cols;
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>

$borderColor: #999;

.data-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid #000;
  font-size: 0.9rem;
  background: #fff;
  max-height: 100vh;

  header {
    background-color: #c3c3c3;
    border-bottom: 1px solid #000;

    .label {
      text-align: center;
      font-weight: bold;
    }

    .filters {
      margin-top: -6px;

      select,
      input[type="text"],
      input[type="numeric"] {
        width: 100%;
        height: 21px;
      }
    }
  }

  main {
    overflow: hidden;
    position: relative;
    flex-grow: 1;

    &:not(:hover) svg.scroll {
      // opacity: 0 !important;
    }
    .row {
      border-bottom: 1px solid $borderColor;

      &:after {
        position: absolute;
        content: ' ';
        left: 100%;
        top: 100%;
        height: 1px;
        display: block;
        width: 10000px;
        background: #ddd;
      }
    }
  }

  .row {
    position: relative;
    display: flex;
  }
}



.row {
  position: relative;
  display: flex;

}

.row > * {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 4px 5px;
  // white-space: nowrap;
  border-right: 1px solid $borderColor;
  flex-shrink: 0;

  &.selected {
    outline-offset: -1px;
    outline: 2px solid blue;
  }
}

svg.vScroll,
svg.hScroll {
  transition: opacity ease 0.3s;
  position: absolute;
  border-radius: 5px;
  z-index: 10;
  background-color: #ddf;
  &:not(:hover) {
    opacity: 0.7;
    filter: grayscale(1);
  }

  rect {
    fill: #99c;
  }
}
svg.vScroll {
  right: 1px;
  top: 1px;
  rect {
    width: 100%;
  }
}
svg.hScroll {
  bottom: 1px;
  left: 1px;
  rect {
    height: 100%;
  }
}

.cell[data-type="number"] {
  text-align: right;
}

</style>
