import axios from "axios";


const api = {};

api.API_URL = 'https://api.openstreetmap.org';


api.versions = function() {
    return axios.get(api.API_URL + '/api/versions');
};

api.capabilities = function() {
    return axios.get(api.API_URL + '/api/capabilities');
};

api.permissions = function(login, password) {
    const url = api.API_URL.replace('://', '://' + login + ':' + password + '@');
    return axios.get(url + '/api/0.6/permissions');
};

api.changeset = {
    create: (login, password, comment) => {
        const url = api.API_URL.replace('://', '://' + login + ':' + password + '@');
        return axios.put(url + '/api/0.6/changeset/create', `
        <osm>
        <changeset>
            <tag k="created_by" v="Node.js + axios"/>
            <tag k="comment" v="${comment} ..."/>
        </changeset>
        </osm>`);
    },
    close: (login, password, id) => {
        const url = api.API_URL.replace('://', '://' + login + ':' + password + '@');
        return axios.put(url + `/api/0.6/changeset/${id}/close`);
    }
};

api.updateNode = function(login, password, id, xml) {
        const url = api.API_URL.replace('://', '://' + login + ':' + password + '@');
        return axios.put(url +  `/api/0.6/node/${id}`, xml);
};

api.updateWay = function(login, password, id, xml) {
        const url = api.API_URL.replace('://', '://' + login + ':' + password + '@');
        return axios.put(url +  `/api/0.6/way/${id}`, xml);
};

export default api;