
export const categories = [
    ['Rue', 'نهج'],
    ['Avenue', 'Boulevard', 'شارع'],
    ['Route', 'طريق'],
    ['Impasse', 'زنقة'],
    ['Place', 'ساحة'],
    ['Passage', 'Allée', 'ممر'],
    ['Ceinture', 'حزام'],
    ['Chemin', 'معبر'],
    ['Ruelle', 'زقاق'],
];

const prefixes = ["des", "de la", "de l'", "de", "d'", "du"];

export function simplifyName(n) {
    const cats = categories.flat().join('|');
    const pref = prefixes.join('|');

    n = n.replace(new RegExp(`\\b(${cats})\\b`, 'i'), '');
    n = n.replace(new RegExp(`\\b(${pref})\\b`, 'i'), '');
    n = n.replace(/ +/g, ' ');
    n = n.trim();
    return n;
}